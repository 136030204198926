import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Mail from "../../data/icons/mail.svg"
import Phone from "../../data/icons/phone.svg"

const Container = styled.div`
& h2{

    font-size:11vw;
    
    @media(min-width:401px){
        font-size:8vw;
    }

    @media(min-width:601px){
        font-size:2.25em;
    }

    text-align:center;
    -ms-grid-column: 1;
    -ms-grid-column-span: 12;
    grid-column: 1/13;
}
`

const ContactContainer = styled.div`
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-orient:vertical;
-webkit-box-direction:normal;
-ms-flex-flow:column wrap;
        flex-flow:column wrap;
-webkit-box-align:center;
-ms-flex-align:center;
        align-items:center;
-webkit-box-pack:space-evenly;
-ms-flex-pack:space-evenly;
        justify-content:space-evenly;

@media(min-width:601px){
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
        -ms-flex-flow:row wrap;
            flex-flow:row wrap;
}
`

const Contact = styled.div`
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-orient:vertical;
-webkit-box-direction:normal;
-ms-flex-flow:column wrap;
        flex-flow:column wrap;
-webkit-box-align:center;
-ms-flex-align:center;
        align-items:center;
-webkit-box-pack:center;
-ms-flex-pack:center;
        justify-content:center;

min-width:30%;
margin: 32px 0;

@media(min-width:601px){
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
        -ms-flex-flow:row wrap;
            flex-flow:row wrap;
}
`

const ImageContainer = styled(GatsbyImage)`
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-align:center;
    -ms-flex-align:center;
        align-items:center;
-webkit-box-pack:center;
    -ms-flex-pack:center;
        justify-content:center;
        width:180px;
& img{
    
    border-radius:200px;
}
`

const TextContainer = styled.div`
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-orient:vertical;
-webkit-box-direction:normal;
    -ms-flex-direction:column;
        flex-direction:column;
-webkit-box-pack:center;
    -ms-flex-pack:center;
        justify-content:center;

& h3 {
    text-align:center;
    line-height:10px;
}

@media(min-width:601px){
    margin-left:25px;

    & h3 {
        text-align:left;
    }
}
`

const Text = styled.div`
margin:0;

display:-webkit-box;

display:-ms-flexbox;

display:flex;
-webkit-box-orient:horizontal;
-webkit-box-direction:normal;
-ms-flex-flow:row wrap;
        flex-flow:row wrap;
-webkit-box-align:center;
-ms-flex-align:center;
        align-items:center;
-webkit-box-pack:center;
-ms-flex-pack:center;
        justify-content:center;

@media(min-width:601px){
    -webkit-box-pack:flex-start;
    -ms-flex-pack:flex-start;
    justify-content:flex-start;
}

& p{
    margin-left: 15px;
	line-height: 16px;
}
& svg {
    height:1.8em;
    width:2em;
}
`

export default function PersonalContact(props){
    return(
        <Container className="row">
            <h2>{props.header}</h2>
            <ContactContainer className="l3-10 m2-11 s1-12">
            {
                props.data.map((item, i) => (
                    <Contact key={i}>
                        <ImageContainer image={getImage(item.image.src)} alt={item.image.alt}/>
                        <TextContainer>
                            <h3>{item.name}</h3>
                            <div>
                                <Text>
                                    <Phone/>
                                    <p>{item.telefonnummer}</p>
                                </Text>
                                <Text>
                                    <Mail/>
                                    <p>{item.email}</p>
                                </Text>
                            </div>
                        </TextContainer>
                    </Contact>
                ))
            }
            </ContactContainer>
        </Container>
    )
}