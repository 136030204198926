import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Layout from "../components/Layout"
import BusinessContact from "../components/BusinessContact"
import PersonalContact from "../components/PersonalContact"
import BottomBanner from "../components/BottomBanner"
import ContactTopBanner from "../components/ContactTopBanner"

export default function Contact({data}) {
  const contact = data.allContactJson.nodes[0]

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>Kontakt</title>
        <html lang="de"/>
      </Helmet>
      <Layout>
      <ContactTopBanner/>
      <BusinessContact data={contact.FirmaKontakt}/>
      <PersonalContact data={contact.ContactInfoPerson} header={contact.header2}/>
      <BottomBanner/>
      </Layout>
    </div>
  )
}

export const query = graphql`
{
  allContactJson{
    nodes{
      title
      FirmaKontakt {
          name
          name2
          telefonnummer
          email
          Adresse {
              Street
              Ort
              Land
          }
      }
      header2
      ContactInfoPerson {
          name
          email
          telefonnummer
          image {
            src{
              childImageSharp{
                gatsbyImageData(
                  quality:100
                  width:180
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            alt
          }
        }
      }
    }
  }
`
